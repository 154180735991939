import http from "../../util/http/baseHttp.js";
const baseUrl = process.env.VUE_APP_API_BASE_URL;

export default {
    /**
     * 获取操作引导
     */
    fetchOperationGuideData:function (param) {
        return  http.get(baseUrl + '/operation/guide/select',param);
    },
    /**
     * 更新操作引导
     */
    updateOperationGuideData:function (param) {
        return  http.post(baseUrl + '/operation/guide/update',param);
    }
}
