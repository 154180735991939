import {HudGlobal} from "@/util/HudGlobal";

import GuideStepHelper from "@/util/guide-step/GuideStepHelper";
import ConstantsGuideCode from "@/util/constants/ConstantsGuideCode";

export default {

    /**
     * 添加步骤
     */
    createLivelearningGuideSteps(element, title, description, position, id, index,isDone,isNext,isPrev,codeList,stageBackground) {
        const that =this;
        return{
            //组件id
            element: element,
            stageBackground:stageBackground?stageBackground:null,
            popover: {
                title: title,
                description: description,
                showButtons: true,
                position:position?position:HudGlobal.getVue().$driverConstants.positionTypeAuto,
                doneBtnText: isDone?'完成':null,
                closeBtnText: '关闭',
                nextBtnText: isDone?'完成':isNext?'下一步':null,
                prevBtnText: isPrev?'上一步':null,
            },
            //唯一识别的id 用户列表中
            id: id,
            index: index,
            onClose: function (val) {
                that.loadUpdateOperationGuideDataWithStep(element,codeList);
            },
            onPrevious: function () {

                // lzb 2020-09-03 视频播放页面滚动到顶部特殊处理 TODO 最佳方案是传参数进入，暂时未这么处理
                if(element == "#step224"){
                    const  node = document.getElementById('tibi-play-page-wrapper');
                    if(node){
                        node.scrollIntoView();
                    }
                }


            }

        };
    },



    /*===========================接口============================
    * 刷新步数
    * */
    loadUpdateOperationGuideDataWithStep(val,stringList){
        const step =  val.substring(1);
        let apendString = '';
        // 总的数据
        let array =  stringList.split(",");
        const  index=array.indexOf(step);
        for (let i in array) {
            let string = array[i];
            if (i <= index) {
                if (i == 0) {
                    apendString = string;
                } else {
                    apendString = apendString + ',' + string;
                }
            }
        }

        console.log('关闭拼接的字符串',step,index,apendString);

        //接口
        GuideStepHelper.loadUpdateOperationGuideData(apendString, function () {

        });
    },

}
