import guideApi from "@/api/guideApi/guideApi";
import {HudGlobal} from "@/util/HudGlobal";

export default {
    /**
     * 获取操作引导
     */
    loadOperationGuideData(guideCodes, callBack){
        const param = {
            guideCodes:guideCodes,
        }
        guideApi.fetchOperationGuideData(param).then(response=>{
            let dataList = [];
            if(response.success){
                dataList = response.data;
            }else {
                dataList = [];
            }
            if(callBack){
                callBack(dataList);
            }
        });
    },
    /**
     * 更新操作引导
     */
    loadUpdateOperationGuideData(guideCodes, callBack){
        const param = {
            guideCodes:guideCodes,
        }
        guideApi.updateOperationGuideData(param).then(response=>{
            if(response.success){

            }else {

            }
            if(callBack){
                callBack();
            }
        });
    }


}
