export default {
    /**
     * 首页列表guideCodes
     */
    homeGuideCodes: "step200",

    /**
     * 准备培训页面guideCodes
     */
    prepareTrainGuideCodes: "step201,step202,step203,step204",

    /**
     * 签到页面guideCodes
     */
    loginTrainGuideCodes: "step206,step207,step208,step209,step210",

    /**
     * 签退页面guideCodes
     */
    loginOutTrainGuideCodes: "step211,step212,step213,step214,step215",

    /**
     * 抽验页面guideCodes
     */
    loginSamplingTrainGuideCodes: "step216,step217,step218,step219,step220",

    /**
     * 视频播放页面guideCodes
     */
    videoPlayerGuideCodes: "step221,step222",

    /**
     * 视频播放页面guideCodes - 抽验完成之后进入 签退提示
     */
    videoPlayerSignOutGuideCodes: "step223,step224",

}

